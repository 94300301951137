import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Spacing from '../../components/partials/Spacing'

import Layout from '../../components/Layout'

// import { getClientId } from '../../helpers/getClientId'

import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
// import ZoominfoFields from '../../components/partials/zoominfo-fields'
// import callZoominfo from '../../helpers/zoomInfo'
// import { getCookieByName } from '../../helpers/getCookieByName'

interface StateProps {
  pressItems: ArrayOfPressItems
}
interface ArrayOfPressItems {
  pressItemsRemaining: Array<number> | Array<PressItem>
}
interface ImageObject {
  gatsbyImageData: any
  title: string
}
interface PressItemValues {
  link: string
  image: ImageObject
  title: string
}
interface PressItem {
  node: PressItemValues
}

const newsroom: React.FC<StateProps> = () => {
  const data = useStaticQuery(graphql`
    query PressSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Press" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
      allContentfulPressItem(sort: { fields: rating, order: ASC }) {
        edges {
          node {
            title
            link
            image {
              title
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node
  const allPressItems = data.allContentfulPressItem.edges
  const pressItemsFirstNine = allPressItems.slice(0, 9)
  const pressItemsRest = allPressItems.slice(9, allPressItems.length)

  const [pressItems, setPressItems] = useState<ArrayOfPressItems>({
    pressItemsRemaining: [],
  })

  const { pressItemsRemaining } = pressItems

  const PressItem = (pressItem) => {
    return (
      <div key={uuidv4()} className="col-md col-sm-6 col-12 mt-1">
        <div className="home-three-items-box border-top-0 rounded-1 mt-4">
          <GatsbyImage
            className="w-100 px-3 rounded-top-1"
            image={pressItem.node.image.gatsbyImageData}
            alt={pressItem.node.image.title}
          />
          <div className="py-4 px-3">
            <p className="h4-like mb-1">Newsroom</p>
            <p className="cera-pro mt-4">{pressItem.node.title}</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="primary-text press-link-box"
              href={pressItem.node.link}
            >
              Read more <i className="red-pointer-right"></i>
            </a>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    // Zoominfo Snippet
    // callZoominfo('uoOiaHNN8N3QdXczOrJm')
    // Getting clientId
    // const interval = setInterval(function () {
    //   if (getClientId()) {
    //     let gaClientId = getClientId()
    //     document.querySelector<HTMLInputElement>('#ga_client_id').value =
    //       gaClientId
    //     clearInterval(interval)
    //   }
    // }, 1000)
  }, [])

  const handleMore = () => {
    setPressItems({
      pressItemsRemaining: pressItemsRest,
    })
  }

  const Scroll = () => {
    document
      .querySelector('#download-kit')
      .scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  // const onSubmit = () => {
  //   document.querySelector<HTMLInputElement>('#splunk_id').value =
  //     getCookieByName('mintjs%3Auuid')
  // }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <div className="hero-margin container-1">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-6 ">
              <h1>DataOps Newsroom</h1>
              <div className="pr-5 pt-3 pb-2">
                <p className="hero-description-dark pt-2">
                  The latest media news and publications on Lenses.io
                </p>
                <div className="d-lg-flex d-block flex-wrap mobile-margin-top">
                  <div className="roll-button mt-4 mr-4">
                    <a
                      className="bg-red text-white cursor-pointer"
                      onClick={Scroll}
                    >
                      <span data-hover="Contact us">Contact us</span>
                    </a>
                  </div>
                  <div className="roll-button mobile-margin-reset mt-4">
                    <a
                      className="bg-light-blue text-white mobile-margin-top-half"
                      href="https://archive.lenses.io/assets/media-kit.zip"
                    >
                      <span data-hover="Media kit">Media kit</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half"
                quality={100}
                src="../../resources/images/press/hero-image.svg"
                placeholder="tracedSVG"
                alt="DataOps Newsroom"
              />
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          <div className="text-center pb-2">
            <h2 className="f-32 fw-900">Recent coverage</h2>
          </div>
          <div className="grid-container grid-container-coverage">
            {pressItemsFirstNine.map((pressItem) => {
              return PressItem(pressItem)
            })}
            {pressItemsRemaining.length > 0 &&
              pressItemsRemaining.map((pressItem) => {
                return PressItem(pressItem)
              })}
          </div>
          {allPressItems.length > 9 && pressItemsRemaining.length == 0 && (
            <div className="text-center mt-5">
              <a href="#!">
                {' '}
                <span
                  className="primary-text cursor-pointer"
                  onClick={handleMore}
                >
                  More &#709;
                </span>
              </a>
            </div>
          )}
        </div>
      </section>
      <Spacing />

      <section className="bg-cyan py-5 mobile-margin-top">
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-10 text-center mobile-text-start">
              <h2 className="f-32 fw-900 mobile-text-center">
                About Lenses.io
              </h2>
              <p>
                Lenses revolutionizes the way organizations deliver new
                real-time services.
                <br /> <br />
                By offering a low-code experience for engineers to operate
                open-source streaming technologies such as Apache Kafka, teams
                can accelerate time-to-market, focusing less on managing
                infrastructure and more on building the next big data product.
              </p>
              <p>
                Today, tens of thousands of developers and hundreds of
                organizations use open-source tools and enterprise software
                created by Lenses.io.
                <br /> <br />
                Companies like Daimler-Benz and Adidas through to cloud-native
                start-ups use Lenses to help run their strategic streaming and
                software applications: IoT, fraud-detection, AI engines and
                cutting-edge consumer-facing applications.
              </p>

              <div className="mt-5 mr-4 mobile-text-center">
                <div className="roll-button">
                  <a
                    className="bg-light-blue text-white"
                    id="newsroom-more-about-us"
                    href="/company/about-us/"
                  >
                    <span data-hover="More about us">More about us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center my-3 mobile-margin-top">
            <h2 className="f-32 fw-900">Media kit</h2>
          </div>
          <div className="d-flex flex-wrap  align-items-center">
            <div className="col-sm col-6 text-center h-100">
              <a href="https://archive.lenses.io/assets/brand-assets.zip">
                <StaticImage
                  className="media-kit-image"
                  quality={100}
                  src="../../resources/images/press/brand-assets.svg"
                  placeholder="tracedSVG"
                  alt="brandassets"
                />
                <div className="cera-pro fw-900 text-uppercase link-dark mt-3">
                  Brand assets
                </div>
              </a>
            </div>
            <div className="col-sm col-6 text-center h-100">
              <a href="https://archive.lenses.io/assets/leadership-team.zip">
                <StaticImage
                  className="media-kit-image"
                  quality={100}
                  src="../../resources/images/press/leadership-team.svg"
                  placeholder="tracedSVG"
                  alt="leadership"
                />
                <div className="cera-pro fw-900 text-uppercase link-dark mt-3">
                  Leadership team
                </div>
              </a>
            </div>
            <div className="col-sm col-6 mobile-margin-top text-center h-100">
              <a href="/blog/">
                <StaticImage
                  className="media-kit-image"
                  quality={100}
                  src="../../resources/images/press/blog.svg"
                  placeholder="tracedSVG"
                  alt="blog"
                />
                <div className="cera-pro fw-900 text-uppercase link-dark mt-3">
                  Lenses.io blog
                </div>
              </a>
            </div>
            <div className="col-sm col-6 mobile-margin-top text-center h-100">
              <a href="https://archive.lenses.io/assets/company-facts.docx">
                <StaticImage
                  className="media-kit-image"
                  quality={100}
                  src="../../resources/images/press/facts.svg"
                  placeholder="tracedSVG"
                  alt="companyfacts"
                />
                <div className="cera-pro fw-900 text-uppercase link-dark mt-3">
                  Company facts
                </div>
              </a>
            </div>
            {/* <div className="col text-center h-100">
                <a href="#!">
                  <img
                    className="media-kit-image"
                    src={analyst}
                    alt="analyst"
                  />
                  <div className="light-blue fw-600 mt-3">
                    Analyst resources
                  </div>
                </a>
              </div> */}
          </div>
          <div className="mt-5 text-center">
            <div className="roll-button">
              <a
                className="bg-light-blue text-white"
                id="download-kit"
                href="https://archive.lenses.io/assets/media-kit.zip"
              >
                <span data-hover="Download kit">Download kit</span>
              </a>
            </div>
          </div>
        </div>
        <Spacing />

        <div className="container-1">
          <div className="bg-dark my-4 rounded-1">
            <div className="p-5 text-center mobile-padding-reset ipad-padding-reset">
              <p className="text-white">
                {' '}
                Contact person: Joanne Blum, Director of Communications
              </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:press@celonis.com"
                className="text-white f-26 fw-900"
              >
                {' '}
                press@celonis.com
              </a>

              {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
              {/* <form
                id="zoominfo-all-fields-form"
                action="https://go.pardot.com/l/877242/2020-10-13/4gh8wb"
                method="POST"
                acceptCharset="UTF-8"
                onSubmit={onSubmit}
              >
                <ZoominfoFields
                  includeCompany={true}
                  includePhone={true}
                  includeJobTitle={true}
                />

                <div className="grid-container grid-press px-5 mx-5 mobile-padding-reset mobile-margin-left-half mobile-margin-right-half">
                  <input
                    id="email"
                    type="email"
                    autoComplete="email"
                    className="border-0 f-13 ml-2 px-3 py-2-5 rounded25 mobile-margin-reset"
                    name="Email"
                    placeholder="Email*"
                    required
                  />
                  <input
                    type="text"
                    name="Company"
                    autoComplete="company"
                    className="border-0 f-13 ml-2 px-3 py-2-5 rounded25 mobile-margin-reset"
                    id="company"
                    placeholder="Company*"
                    required
                  />

                  <input
                    name="GA Client ID"
                    type="hidden"
                    className="d-none"
                    id="ga_client_id"
                  />

                  <input
                    type="hidden"
                    name="Splunk ID"
                    id="splunk_id"
                    className="d-none"
                  />

                  <textarea
                    rows={1}
                    id="textarea"
                    className="m-2 form-control px-3 pb-5 pt-2 rounded25 grid-press-textarea mobile-margin-reset"
                    name="LEADCF24"
                    maxLength={2000}
                    placeholder="Message*"
                    required
                  />
                </div>
                <button
                  className="bg-red text-white form-newsroom-button  f-14 mt-4 pt-1 px-1 fw-700 cursor-pointer border-0 mobile-margin-bottom-half"
                  id="formsubmit"
                  type="submit"
                >
                  Send Message
                </button>
              </form> */}
            </div>
          </div>
        </div>
        <Spacing />
      </section>
    </Layout>
  )
}

export default newsroom
